<template>
    <div class="nav">
        <el-menu :default-active="activeNav" class="el-menu-demo" mode="horizontal" text-color="#fff"
            active-text-color="#fff" background-color="#CD0000">
            <template v-for="(item, i) in list">
                <el-submenu :index="String(item.id)" v-if="item.children" :key="i">
                    <template slot="title"> {{ item.name }}</template>
                    <el-menu-item @click.native="Jump(items)" v-for="(items, j) in item.children" :key="j"
                        :index="items.title">{{ items.name }}</el-menu-item>
                </el-submenu>
                <el-menu-item @click.native="Jump(item)" v-else :index="item.title" :key="i">{{
                    item.name
                }}</el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { menu } from '@/api/login'
import { member_apply } from '@/api/commit'
export default {
    data() {
        return {
            activeNav: '',
            list: [],
        };
    },
    watch: {
        $route(to) {
            this.activeNav = to.path
        }
    },
    created() {
        this.getmenu()
    },
    mounted() {
        this.activeNav = this.$route.path
    },

    methods: {
        Jump(data) {
            console.log(data)
            if (data) {
                if (data.pid == 41) {
                    if (data.id == 42) {
                        localStorage.setItem('num', 1)
                    } else if (data.id == 43) {
                        localStorage.setItem('num', 2)
                    } else if (data.id == 44) {
                        localStorage.setItem('num', 3)
                    } else {
                        localStorage.setItem('num', 4)
                    }
                    localStorage.setItem('title', data.name)
                    this.$router.push({ path: data.link })
                    setTimeout(() => {
                        window.location.reload()
                    }, 50);
                } else {
                    this.$router.push({ path: data.link })
                }

            }
        },
        getmember_apply() {
            member_apply().then(res => {
                if (res.code == 200) {
                    for (var i in this.list[3].children) {
                        if (res.data.apply_type == 1 && res.data.flag == true) {
                            if (this.list[3].children[i].id == 11) {
                                this.list[3].children[i].link = '/member'
                            }
                            if (this.list[3].children[i].id == 12) {
                                this.list[3].children.splice(this.list[3].children.indexOf(this.list[3].children[i]), 1)
                            }
                        } else if (res.data.apply_type == 2 && res.data.flag == true) {
                            if (this.list[3].children[i].id == 12) {
                                this.list[3].children[1].link = '/member_unit'
                            }
                            if (this.list[3].children[i].id == 11) {
                                this.list[3].children.splice(this.list[3].children.indexOf(this.list[3].children[i]), 1)
                            }
                        }
                    }
                }
            })
        },  








        
        getmenu() {
            menu().then(res => {
                if (res.code == 200) {
                    this.list = res.data
                    console.log(this.list)
                    localStorage.setItem('menuList', JSON.stringify(res.data))
                    if (localStorage.getItem('sheixetoken')) {
                        this.getmember_apply()
                    }
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.nav {
    background: #CD0000;
    color: #fff;
    height: 60px;


    .el-menu {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;

        ::v-deep .el-submenu__title i {
            color: #fff;
        }
        ::v-deep .el-submenu__title  {
            border: none !important;
            font-size: 18px !important;
        }

        ::v-deep .el-menu-item {
            float: none !important;
            border: none !important;
            font-size: 18px !important;
        }
    }
}
</style>
<style>
.el-menu--popup {
    min-width: 116px !important;
    text-align: center;
}
</style>
